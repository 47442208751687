import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { map, get, keys } from 'lodash-es'
import Page from '../components/page'
import SectionStack from '../components/section-stack'
import VideoSection from '../sections/VideoSection/VideoSection'
import PortfolioSection from '../sections/PortfolioSection/PortfolioSection'
import CarouselSection from '../sections/CarouselSection/CarouselSection'
import { isEmpty } from 'lodash'

const RootIndex = (props) => {
    const siteTitle = get(props, 'data.site.siteMetadata.title')

    const settings = get(props, 'data.settings.nodes.0', {})

    const projects = map(get(props, 'data.projects.edges'), 'node')

    const globals = {
        projectCategories: map(get(props, 'data.projectCategories.edges'), 'node'),
    }

    const hasReel =
        !isEmpty(settings.homepageReelVimeoId) &&
        settings.homepageReelVimeoId != settings.homepageReelPreviewVimeoId

    return (
        <Page
            location={props.location}
            siteTitle={siteTitle}
            navbarRetainer={false}
            hasReel={hasReel}
        >
            {settings.homepageIntroType == 'Carousel' ? (
                <CarouselSection slides={settings.homepageCarouselSlides || []} />
            ) : (
                <VideoSection
                    id="reel"
                    variant="Fullwidth Video"
                    vimeoId={settings.homepageReelVimeoId}
                    previewVimeoId={settings.homepageReelPreviewVimeoId}
                    posterImage={settings.homepageReelPosterImage}
                    playText={settings.homepageReelPlayText}
                />
            )}

            <PortfolioSection
                globals={globals}
                title={settings.homepageProjectsHeadingText}
                projects={projects}
                style={{}}
            />
        </Page>
    )
}

export default RootIndex

export const pageQuery = graphql`
    query HomeQuery {
        ...globalsFragment
        ...projectsFragment
        ...projectCategoriesFragment
    }
`
