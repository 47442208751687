import React, { Component, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { isEmpty, throttle, bind, noop, uniqueId } from 'lodash-es'

import { onMobile, onDesktop, isMobile, desktopQuery } from '@src/utils/responsive'

import Section from '@src/components/section'
import { useCurrentSection, createResponsiveComponent } from '@src/utils/'
import Slider from 'react-slick'
import { Media } from '@src/components/Media'
import { typeBody24, typeHeading50 } from '@src/components/typography'
import Container from '@src/components/container'
import { useResponsiveContext } from '@src/utils/responsive'
import { scrollTo } from '@src/utils/'

import { dispatchEvent } from '@src/utils/dispatch-event'
import { get } from 'lodash'

const CarouselSectionRoot = styled.div`
    position: relative;
`

const CarouselSectionSlide = styled.div`
    position: relative;
`

const CarouselSectionSlideText = styled(Container)`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
`

const CarouselSectionTitle = styled.p`
    ${typeHeading50}

    margin: 1.2rem 0;

    ${onDesktop`
        margin: 1.8rem 0 2.8rem 0;
    `}
`

const ScrollDownHint = styled.p`
    position: absolute;
    left: 0;
    bottom: 5.2rem;
    right: 0;
    text-align: center;

    ${typeBody24}

    transition: color 0.3s ease, opacity 0.3s ease;
    cursor: pointer;
    opacity: 1;
    &:hover {
        opacity: 0.5;
    }
`

const defaultOverlayColor = 'black'

const getMediaNavbarColor = (media) =>
    get(get(media, 'description', '').match(/\[\s*navbarColor\s*=\s*([^\]]*)?\]/i), 1) ||
    defaultOverlayColor

const CarouselSection = ({ slides, children, showTitle, ...props }) => {
    const { isMobile, aspectRatio, isDesktop } = useResponsiveContext()

    const [overlayColor, setOverlayColor] = useState(getMediaNavbarColor(slides[0]))
    const [showScrollDownHint, setShowScrollDownHint] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            dispatchEvent('carousel:slide-change', {
                index: 0,
                media: slides[0],
                navbarColor: getMediaNavbarColor(slides[0]),
            })
        }, 50)

        let hasScrolled = false

        function onScroll() {
            hasScrolled = true

            window.removeEventListener('scroll', onScroll)
        }

        window.addEventListener('scroll', onScroll)

        setTimeout(() => {
            setShowScrollDownHint(!hasScrolled)

            let hasScrolledAfterAppearing = false
            function onScrollAfterAppearing() {
                if (!hasScrolledAfterAppearing) {
                    hasScrolledAfterAppearing = true
                    window.removeEventListener('scroll', onScrollAfterAppearing)

                    setTimeout(() => {
                        setShowScrollDownHint(false)
                    }, 1000)
                }
            }
            window.addEventListener('scroll', onScrollAfterAppearing)
        }, 7000)
    }, [])

    return (
        <CarouselSectionRoot {...props}>
            <Slider
                {...{
                    infinite: true,
                    autoplaySpeed: 2700,
                    autoplay: true,
                    beforeChange: (_, index) => {
                        const media = get(slides, index, {})

                        const newOverlayColor = getMediaNavbarColor(media)

                        setOverlayColor(newOverlayColor)

                        dispatchEvent('carousel:slide-change', {
                            index,
                            media,
                            navbarColor: newOverlayColor,
                        })
                    },
                }}
            >
                {slides.map((media, key) => (
                    <CarouselSectionSlide key={key}>
                        <Media {...media} aspectRatio={isMobile ? 4 / 5 : aspectRatio} />
                        <CarouselSectionSlideText>
                            <CarouselSectionTitle style={{ color: getMediaNavbarColor(media) }}>
                                {media.title}
                            </CarouselSectionTitle>
                        </CarouselSectionSlideText>
                    </CarouselSectionSlide>
                ))}
            </Slider>
            {isDesktop && (
                <ScrollDownHint
                    style={{ color: overlayColor, opacity: showScrollDownHint ? 1 : 0 }}
                    onClick={() =>
                        scrollTo('#work', {
                            duration: 0,
                        })
                    }
                >
                    Scroll Down
                </ScrollDownHint>
            )}
            {children}
        </CarouselSectionRoot>
    )
}

export default CarouselSection
